import React from 'react';
import '../styles/home.css';

interface IGeneralButtonProps{
message: string;
icon?: string;
style: string;
type?: 'button' | 'submit' | 'reset' | undefined;
handleClick?: any;
disabled?: boolean;
}

class GeneralButton extends React.Component<IGeneralButtonProps>{
  render(){
    const icon = this.props.icon ? <i className={`fas ${this.props.icon}`}></i> : '';
    
    return <button className={this.props.style}
    type={this.props.type || 'submit'}
    disabled={this.props.disabled || false}
    onClick={() => this.props.handleClick && this.props.handleClick()}>
      {this.props.message}
      {icon}
    </button>
  }
}

export default GeneralButton;

