import * as React from 'react';
import GeneralButton from '../components/generalButton';
import { useHistory, Route } from 'react-router-dom';

interface ILinkButtonProps {
  message: string;
  style: string;
  route: string | { url: string; query: Object };
}
function LinkButton(props: ILinkButtonProps) {
  const history = useHistory();

  const extractQueryParams = (queryObj: any) => {
    return Object.keys(queryObj).reduce((qStr, key) => {
      if (qStr) {
        return `${qStr}&${key}=${queryObj[key]}`;
      } else {
        return `${key}=${queryObj[key]}`;
      }
    }, '');
  };

  const route =
    typeof props.route === 'string'
      ? props.route
      : `${props.route.url}?${extractQueryParams(props.route.query)}`;

  const clickHandler = () => {
    history.push(route);
  };

  return (
    <GeneralButton style={props.style} message={props.message} handleClick={() => clickHandler()} />
  );
}

export default LinkButton;
