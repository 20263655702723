import React from 'react';
import '../../styles/models.css';
import pvAnillo from '../../assets/modelos/pv-anillo.jpg';
import ptd from '../../assets/modelos/PTD.jpg';
import ptb from '../../assets/modelos/PTB.jpg';
import pvt from '../../assets/modelos/pv-tcho.jpg';
import pvCuadrado from '../../assets/modelos/pv-cuadrato.jpg';
import DropDownFiles from '../../components/dropDownFiles';
import { Header } from '../../components/header';

class Axiales extends React.Component{
  render(){
    return <div>
      <Header />
      <div className='models'>
        <h4>Axiales</h4>
        <div className='models-container'>
          <div className='product-model'>
            <div className='img-box'><img src={pvAnillo} /></div>
            <div className='title-description'>
              <h5>Serie: PV Anillo</h5>
              <p>Diseñados para trabajar con grandes volúmenes de aire con bajos consumos, 
              rotores de 3 paletas de aluminio de alta capacidad tipo "Q" aseguran un bajo nivel sonoro, 
              AIRFOIL de 5 a 9 palas de aleación de aluminio fundido, 
              permiten trabajar con presiones estáticas mas elevadas.</p>
              <DropDownFiles  
                icon='fa-angle-down'
                text='Ver Ficha Técnica'
                style='btn-ficha'
                styleDropDown='file-link'
                name='Serie PV Anillo'
                file={`${process.env.PUBLIC_URL}/documents/PV-ANILLO.pdf`}
              />
            </div>
          </div>
          <div className='product-model'>
            <div className='img-box'><img src={ptd} /></div> 
            <div className='title-description'>
              <h5>Serie PTD</h5>
              <p>De acople directo, especialmente preparados para intercalar, en tramos de conductos bridados, 
              apropiados para toda aplicación de ventilación y aire acondicionado, hay unidades portátiles 
              de inclinación graduable con caballete móvil para enviar grandes volúmenes desde zonas frías 
              distantes hasta 20 mts. hacia zonas calientes para posibilitar el trabajo en las proximidades de hornos, 
              fundiciones, etc.</p>
              <DropDownFiles  
                icon='fa-angle-down'
                text='Ver Ficha Técnica'
                style='btn-ficha'
                styleDropDown='file-link'
                name='Serie PTD'
                file={`${process.env.PUBLIC_URL}/documents/PTD.pdf`}
              />
            </div>
          </div>
          <div className='product-model'>
            <div className='img-box'><img src={ptb} /></div>
            <div className='title-description'>
              <h5>Serie PTB</h5>
              <p>Con transmisión a correas, motor exterior al flujo de aire, con cubrecorreas capsulado, 
              para aplicaciónes industriales que exigen la remoción de emanciones, humos, vapores, pinturas, 
              gases explosivos, etc. <br />
              Reemplazan a una sección de conducto de igual tamaño, pueden ser instalados en cualquier posición.</p>
              <DropDownFiles  
                icon='fa-angle-down'
                text='Ver Ficha Técnica'
                style='btn-ficha'
                styleDropDown='file-link'
                name='Serie PTB'
                file={`${process.env.PUBLIC_URL}/documents/PTB.pdf`}
              />
            </div>
          </div>
          <div className='product-model'>
            <div className='img-box'><img src={pvt} /></div>
            <div className='title-description'>
              <h5>Serie PV-Techo</h5>
              <p>De acople directo cuya silueta baja y estética es especial para edificios modernos, 
                construidos en chapa de hierro con casquete pintados con pintura epoxi, para uso en intemperie. 
                El funcionamiento silencioso las hace ideales para escuelas, comercios, restaurantes, etc.</p>
                <DropDownFiles  
                icon='fa-angle-down'
                text='Ver Ficha Técnica'
                style='btn-ficha'
                styleDropDown='file-link'
                name='Serie PV-Techo'
                file={`${process.env.PUBLIC_URL}/documents/PV-TECHO.pdf`}
              />
            </div>
          </div>
          <div className='product-model'>
            <div className='img-box'><img src={pvCuadrado} /></div>
            <div className='title-description'>
              <h5>Serie PV-Cuadrato</h5>
              <p>No requiere instalación especializada, ni gran mantenimiento. De color standard o a elección, 
                según la cantidad.Rotores en aleación de aluminio especial y estampadas, con palas tipo Q aseguran 
                bajo nivel de sonido. Su diseño es óptimo para obtener el máximo caudal con mínimo consumo.</p>
                <DropDownFiles  
                icon='fa-angle-down'
                text='Ver Ficha Técnica'
                style='btn-ficha'
                styleDropDown='file-link'
                name='Serie PV Anillo'
                file={`${process.env.PUBLIC_URL}/documents/PV-CUADRATO.pdf`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default Axiales;