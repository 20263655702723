import React from 'react';
import {Header} from '../../components/header';
import '../../styles/models.css';
import b15 from '../../assets/modelos/B15VR.jpg';
import b15c from '../../assets/modelos/B15C.jpg';
import b204 from '../../assets/modelos/B204.jpg';
import b150 from '../../assets/modelos/B150.jpg';
import DropDownFiles from '../../components/dropDownFiles';


class Industriales extends React.Component{
  render(){
    return <div>
      <Header />
      <div className='models'>
        <h4>Cortinas Industriales</h4>
        <div className='models-container'>
          <div className='product-model'>
            <div className='img-box'><img src={b15} /></div>
            <div className='title-description'>
              <h5>Serie: B-15VR</h5>
              <p>Cubre adecuadamente aberturas de hasta 3.5 m. sin elevar el nivel sonoro y con un reducido tamaño. 
              Con opción de resistencia calefactoria.<br />
              Motor monofásico.<br />
              <strong>Dimensiones:</strong> 630 x 390 x 360 Cubre 1 x 3.5 mts.</p>
              <DropDownFiles
                icon='fa-angle-down'
                text='Ver Ficha Técnica'
                style='btn-ficha'
                styleDropDown='file-link'
                name='Serie B-15-VR'
                file={`${process.env.PUBLIC_URL}/documents/B-15-VR.pdf`}
              />
            </div>
          </div>
          <div className='product-model'>
            <div className='img-box'><img src={b15c} /></div>
            <div className='title-description'>
              <h5>Serie B-20/2</h5>
              <p>Con 2800 RPM, es la cortina ideal para cierre de cámaras frigoríficas y uso en lugares donde 
              es necesario retener altos niveles de frigorías.<br />
              Motor monofásico.<br />
              <strong>Dimensiones:</strong> 630 x 390 x 360 Cubre 1 x 3.5 mts.</p>
              <DropDownFiles
                icon='fa-angle-down'
                text='Ver Ficha Técnica'
                style='btn-ficha'
                styleDropDown='file-link'
                name='Serie B-20/2'
                file={`${process.env.PUBLIC_URL}/documents/B-20-2.pdf`}
              />
            </div>
          </div>
          <div className='product-model'>
            <div className='img-box'><img src={b204} /></div>
            <div className='title-description'>
              <h5>Serie Serie B-20/4</h5>
              <p>Brinda similar prestación que el modelo B-15 pero con Motor trifásico y opción de resistencia 
              calefactoria.<br />
              <strong>Dimensiones:</strong> 630 x 390 x 360 Cubre 1 x 2.5 mts.</p>
              <DropDownFiles
                icon='fa-angle-down'
                text='Ver Ficha Técnica'
                style='btn-ficha'
                styleDropDown='file-link'
                name='Serie B-20/4'
                file={`${process.env.PUBLIC_URL}/documents/B-20-4.pdf`}
              />
            </div>
          </div>
          <div className='product-model'>
            <div className='img-box'><img src={b150} /></div>
            <div className='title-description'>
              <h5>Serie B-150</h5>
              <p>Línea industrial; para portones de fábricas y depósitos, ofrece un gran caudal y 
                permite cubrir distintas alturas, según la necesidad.<br />
                Motor trifásico.<br />
                <strong>Dimensiones:</strong> 1375 x 865 x 715 Cubre 1.5 x 5 mts.</p>
                <DropDownFiles
                icon='fa-angle-down'
                text='Ver Ficha Técnica'
                style='btn-ficha'
                styleDropDown='file-link'
                name='Serie B-150'
                file={`${process.env.PUBLIC_URL}/documents/B-150-2.pdf`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default Industriales;