import React from 'react';
import {Header} from '../components/header';
import '../styles/products.css';
import { Link } from 'react-router-dom';


class Ventiladores extends React.Component{
  render(){
    return <div>
      <Header />
      <div className='products'>
      <h3>Ventiladores</h3>
      <div className='products-container'>
        <Link to='/ventiladores-axiales' className='link-product'>Ventiladores Axiales</Link>
        <Link to='/ventiladores-centrífugos' className='link-product'>Ventiladores Centrífugos</Link>
        <Link to='/ventiladores-turbo-sopladores' className='link-product'>Turbo Sopladores</Link>
        <Link to='/ventiladores-serie-pesada' className='link-product'>Serie Pesada</Link>
        <Link to='/ventiladores-filtros-industriales' className='link-product'>Filtros Industriales</Link>
      </div>
      </div> 
    </div>
  }
}

export default Ventiladores;