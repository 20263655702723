import React from 'react';
import {Header} from '../../components/header';
import '../../styles/models.css';
import mil from '../../assets/modelos/serie1000.jpg';
import dosmil from '../../assets/modelos/Serie2000.jpg';
import DropDownFiles from '../../components/dropDownFiles';

class Pesada extends React.Component{
  render(){
    return <div>
      <Header />
      <div className='models'>
        <h4>Serie Pesada</h4>
        <div className='models-container'>
          <div className='product-model'>
            <div className='img-box'><img src={mil} /></div>
            <div className='title-description'>
              <h5>Serie 1000 Industriales</h5>
              <p>Escpacialmente aptos para la extracción de emanaciones, polvos, virutal y otras aplicaciones 
              industriales. El diseño funcional permite una instalación rápida, fácil y económica.<br />
              Rango amplio de tamaños para satisfacer la mayoría de los requerimientos.</p>
            </div>
          </div>
          <div className='product-model'>
            <div className='img-box'><img src={dosmil} /></div>
            <div className='title-description'>
              <h5>Serie 2000 Industriales</h5>
              <p>Nueva y completa línea diseñada para el control moderno de aire contaminado, sistema de 
              procesamiento industrial, transporte neumático, etc.<br />
              De construcción extra pesada, con 4 rotores a elección: AH - MH - LS - LR, para una vasta gama 
              de temperaturas y aplicaciones con gases corrosivos y cargados de virutas y partículas.</p>
              <DropDownFiles 
                icon='fa-angle-down'
                text='Ver Fichas Técnicas'
                style='btn-ficha'
                styleDropDown='file-link'
                name='Serie 2000 - LS'
                file={`${process.env.PUBLIC_URL}/documents/Serie Industrial 2000 - LS.pdf`}
                name1='Serie 2000 - MH'
                file1={`${process.env.PUBLIC_URL}/documents/Serie Industrial 2000 - MH.pdf`}
                name2='Serie 2000 - AH'
                file2={`${process.env.PUBLIC_URL}/documents/Serie Industrial 2000 - AH.pdf`}
                name3='Serie 2000 - LR'
                file3={`${process.env.PUBLIC_URL}/documents/Serie Industrial 2000 - LR.pdf`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default Pesada;