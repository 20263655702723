import React from 'react';
import GeneralButton from './generalButton';
import '../styles/models.css';

interface IDropDownFiles{
  text: string;
  style: string;
  icon?: string;
  styleDropDown: string;
  name: string;
  file: string;
  name1?: string;
  file1?: string;
  name2?: string;
  file2?: string;
  name3?: string;
  file3?: string;
}


class DropDownFiles extends React.Component<IDropDownFiles, {showItems: boolean, showFiles: boolean;}>{
  state = { 
    showItems: false,
    showFiles: false
  };

  private onClickHandler(showItems: boolean){
    this.setState({showItems})
  }


  private getFiles(){
    if (this.state.showItems){
      return  <div className={this.props.styleDropDown}>
        <a className={this.state.showFiles ? ' ' : 'file'} href={this.props.file} target='_blank'>{this.props.name}</a>
        <a className={this.state.showFiles ? ' ' : 'file'} href={this.props.file1} target='_blank'>{this.props.name1}</a>
        <a className={this.state.showFiles ? ' ' : 'file'} href={this.props.file2} target='_blank'>{this.props.name2}</a>
        <a className={this.state.showFiles ? ' ' : 'file'} href={this.props.file3} target='_blank'>{this.props.name3}</a>
      </div>
    } else {
      return null;
    }
  }

  render(){
    return <div className='drop-down-file'>
    <GeneralButton
      message={this.props.text || ''}
      style={this.props.style}
      handleClick={() => this.onClickHandler(!this.state.showItems)}
      icon={this.props.icon}
    />
      {this.getFiles()}
    </div>
  }
}

export default DropDownFiles;