import React from 'react';
import {Header} from '../components/header';
import '../styles/home.css';
import portada from '../assets/cta-img.jpg';
import  ImgLink  from '../components/imgLink';
import ventilador from '../assets/ventilador.jpg';
import cortinas from '../assets/cortina.jpg';
import worker from '../assets/about-us-img.jpg';
import Form from '../components/form';
import Footer from '../components/footer';
import GeneralButton from '../components/generalButton';
import CarouselBrand from '../components/carousel';
import Whatsapp from '../components/whatsapp';
import { HashLink as Link } from 'react-router-hash-link';
import attach from '../assets/attach.svg';

class Home extends React.Component{
  render(){
    return <div>
      <Header />
      <Whatsapp />
      <div className='top'>
        <img src={portada} />
        <div className='cta'>
          <h1>Líder en equipos de ventilación industrial</h1>
          <div className='ticks'>
            <p>✔ Asesoramiento pre-venta</p> 
            <p>✔ Seguimiento y control post-venta</p> 
            <p>✔ 1 año de garantía</p> 
          </div>
          <Link smooth to='/home/#contacto'>
            <GeneralButton 
            message='Contactanos'
            style='btn'
          />
          </Link>
        </div>
        </div>
        <div className='products'>
          <h2>Productos</h2>
          <hr className='line' />
          <div className='img-links-container'>
            <ImgLink 
            image={ventilador}
            title='Ventiladores'
            style='v-link'
            link='/ventiladores'
            />
            <ImgLink 
            image={cortinas}
            title='Cortinas de aire'
            style='v-link'
            link='/cortinas-de-aire'
            />
          </div>
        </div>
        <div className='about-us'>
        <div id='nosotros'></div>
          <div className='about-us-text'>
            <h2>Nosotros</h2>
            <div className='line-content'><hr className='line-white' /></div>
            <p>Con más de 55 años ofreciendo la más amplia variedad de soluciones 
              en movimiento de aire a la Argentina y el mundo, ICM se posiciona a la 
              vanguardia en la industria de la ventilación.
              Ofrecemos a nuestros clientes, un asesoramiento permanente durante la pre-venta 
              y un seguimiento exhaustivo de post-venta con relación a toda nuestra línea de productos, 
              que cuenta con la más amplia gama de modelos y variantes posibles que el mercado requiere.
              </p>
          </div>
          <div className='about-us-img'>
            <img src={worker}/>
          </div>
        </div>
        <div className='clinets'>
        <div id='clientes'></div>
          <h2>Clientes</h2>
          <hr className='line' />
          <div className='clients-carrusel'>
            <CarouselBrand />
          </div>
        </div>
        <div className='contact'>
          <div id='contacto'></div>
          <h2>Contacto</h2>
          <hr className='line' />
          <div className='map'>
          <iframe src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=es&amp;q=Godoy%20Cruz%201663,%20La%20Tablada,%20Buenos%20Aires.+(ICM%20Ventilaci%C3%B3n)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
          </div>
          <div className='contact-container'>
          <div className='conact-form'>
            <Form />
          </div>
          <div className='contact-info'>
            <h3>Teléfonos:</h3>
            <p>3526-3959 - 3532-6467</p>
            <div className='interno'>
              <h3>Interno Ventas:</h3>
              <p>302</p>
            </div>
            <h3>Dirección:</h3>
            <p>Godoy Cruz 1663, La Tablada, Buenos Aires.</p>
          </div>
        </div>
        </div>
        <div className='work-with-us'>
          <div className='work-with-us-container'>
            <h3>Trabajá con nosotros</h3>
            <p>Sumate a nuestro equipo</p>
            <form className='form-work' action="work.php" method="post">
              <input type="email" name="email" id="email"  placeholder='Email'/>
              <label className="file-upload">
                <img src={attach} alt="" />
                <input type="file" name="file" id="file"/>
                Adjuntar CV
              </label>
              <button type='submit'>Enviar CV</button>
            </form>
            </div>
        </div>
        <Footer />
      </div>
  }
}

export default Home;