import React from 'react';
import {Header} from '../../components/header';
import '../../styles/models.css';
import cf10 from '../../assets/modelos/CF10.jpg';
import b10 from '../../assets/modelos/B10.jpg';
import b15c from '../../assets/modelos/B15C.jpg';
import DropDownFiles from '../../components/dropDownFiles';

class Comerciales extends React.Component{
  render(){
    return <div>
      <Header />
      <div className='models'>
        <h4>Cortinas Comerciales</h4>
        <div className='models-container'>
          <div className='product-model'>
            <div className='img-box'><img src={cf10} /></div>
            <div className='title-description'>
              <h5>Serie: CF-10</h5>
              <p>Moderno diseño, bajo nivel sonoro. Por su sistema de toma de aire permite colocar los módulos juntos. 
              Ideal para comercios y hoteles.<br />
              Motor monofásico.<br />
              <strong>Dimensiones:</strong> 1005 x 240 x 280 Cubre 1 x 2.5 mts.</p>
              <DropDownFiles  
                icon='fa-angle-down'
                text='Ver Fichas Técnicas'
                style='btn-ficha'
                styleDropDown='file-link'
                name='Serie CF-10'
                file={`${process.env.PUBLIC_URL}/documents/CF10.pdf`}
              />
            </div>
          </div>
          <div className='product-model'>
            <div className='img-box'><img src={b10} /></div>
            <div className='title-description'>
              <h5>Serie B-10</h5>
              <p>Excelente caudal y reducido tamaño. Se aplica en puertas hasta 2.15 m. de altura y donde 
              no existen grandes corrientes de aire. Calefacción.<br />
              Motor monofásico.<br />
              <strong>Dimensiones:</strong> 660 x 235 x 280 Cubre 1 x 2.5 mts.</p>
              <DropDownFiles  
                icon='fa-angle-down'
                text='Ver Fichas Técnicas'
                style='btn-ficha'
                styleDropDown='file-link'
                name='Serie B-10'
                file={`${process.env.PUBLIC_URL}/documents/B-10.pdf`}
              />
            </div>
          </div>
          <div className='product-model'>
            <div className='img-box'><img src={b15c} /></div>
            <div className='title-description'>
              <h5>Serie B-15</h5>
              <p>Su excelente caudal, tamaño y nivel sonoro, le permiten cubrir una amplia gama de requerimientos. 
              Muy usada en Shoppings e Hipermercados. Calefacción.<br />
              Motor monofásico.<br />
              <strong>Dimensiones:</strong> 630 x 390 x 360 Cubre 1 x 2.5 mts.</p>
              <DropDownFiles  
                icon='fa-angle-down'
                text='Ver Fichas Técnicas'
                style='btn-ficha'
                styleDropDown='file-link'
                name='Serie B15C'
                file={`${process.env.PUBLIC_URL}/documents/B15C.pdf`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default Comerciales;