import React from 'react';
import {Header} from '../components/header';
import '../styles/products.css';
import { Link } from 'react-router-dom';


class Cortinas extends React.Component{
  render(){
    return <div>
      <Header />
      <div className='products'>
      <h3>Cortinas de aire</h3>
      <div className='products-container'>
        <Link to='/cortinas-de-aire-comerciales' className='link-product'>Cortinas Comerciales</Link>
        <Link to='/cortinas-de-aire-industriales' className='link-product'>Cortinas Industriales</Link>
      </div>
      </div>
    </div>
  }
}

export default Cortinas;