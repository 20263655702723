import React from 'react';
import '../styles/home.css';
import GeneralButton from '../components/generalButton';

class Form extends React.Component{
  render(){
    return <form className='form-container' action="contacto.php" method="post">
            <p>Nombre:</p>
            <input type="text" name="nombre" id="nombre" placeholder='Tu Nombre' required/>
            <p>Empresa:</p>
            <input type="text" name="empresa" id="empresa" placeholder='Tu Empresa' required/>
            <p>Email:</p>
            <input type="email" name="email" id="email" placeholder='Tu Email' required/>
            <p>Escribe tu consulta aquí:</p>
            <textarea name="f_comentarios" id="f_comentarios" placeholder='Mensaje...' required></textarea>
            <GeneralButton 
          message='Enviar Consulta'
          style='btn'
          type='submit'
          />
    </form>
  }
}

export default Form;