import { useEffect, useState } from 'react';
import '../../styles/popUp.scss';
import  x  from '../../assets/close.svg';

export const PopUp = () => {
  const [popUp, setPopUp ] = useState(false);

  useEffect( () => {
    setTimeout( 
      () => setPopUp(true),
      4000
     )
  } ,[])

  const close = () => setPopUp(false)

  return (
    <>{
      popUp ?
      <div className='pop-up-container'>
        <div className='pop-up-box'>
          <div className='pu-text'>
            <img src={x} className='close' onClick={close}></img>
            <h4>¡Nuevo Catálogo!</h4>
            <h3>Cortinas de aire </h3>
            <p>Descubre nuestro nuevo listado de productos <span>2023</span>.</p>
            <a className='download-btn' href={`${process.env.PUBLIC_URL}/documents/ICM Cortinas de Aire.pdf`} download>DESCARGAR AHORA</a>
          </div>
          <div className='pu-img'></div>
        </div>
      </div> :
      null
    }</>   
  )
}
