import React from 'react'
import whatsapp from '../assets/whatsapp.png'
import '../styles/home.css';

class Whatsapp extends React.Component{
  render(){
    return <div className='whatsapp'><a href='https://wa.link/druwr3' target='label'><img src={whatsapp}/></a></div> 
  }
}

export default Whatsapp;