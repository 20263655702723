import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import unilever from '../assets/logos/unilever.png';
import arcor from '../assets/logos/Arcor.png';
import american from '../assets/logos/american.png';
import bayer from '../assets/logos/bayer.png';
import subte from '../assets/logos/subte.jpg';
import sheraton from '../assets/logos/sheraton.png';
import molinos from '../assets/logos/molinos.png';
import nestle from '../assets/logos/Nestle.png';
import '../styles/carousel.css';
 
class CarouselBrand extends React.Component{
  render(){
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
        slidesToSlide: 5 // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
      }
    };

    return <Carousel
      swipeable={false}
      draggable={false}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={true}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
      >
    <div className='img-carousel'><img src={unilever} /></div>
    <div className='img-carousel'><img src={american} /></div>
    <div className='img-carousel'><img src={arcor} /></div>
    <div className='img-carousel'><img src={nestle} /></div>
    <div className='img-carousel'><img src={bayer} /></div>
    <div className='img-carousel'><img src={molinos} /></div>
    <div className='img-carousel'><img src={subte} /></div>
    <div className='img-carousel'><img src={sheraton} /></div>
  </Carousel>;
  }
} 

export default CarouselBrand; 