import React from 'react';
import {Header} from '../../components/header';
import '../../styles/models.css';
import turboR from '../../assets/modelos/turbo_r.jpg';
import DropDownFiles from '../../components/dropDownFiles';


class Turbos extends React.Component{
  render(){
    return <div>
      <Header />
      <div className='models'>
        <h4>Turbo Sopladores</h4>
        <div className='models-container'>
          <div className='product-model'>
            <div className='img-box'><img src={turboR} /></div>
            <div className='title-description'>
              <h5>Serie Turbos R y T</h5>
              <p>Gran selección de modelos de construcción extrapesada, cubriendo una amplia gama de 
                capacidades y presiones. Apropiados para aire y combustión y otras aplicaciones de alta presión.<br />
                <strong>El modelo R</strong>satisface requerimientos industriales de alta presión y bajo volumen, son rotables a 
                posiciónes de descarga standard.<br />
                <strong>El modelo T</strong> con diseño de acople directo y rotor de paletas inclinadas hacia atrás, 
                cubre una amplia gama de caudales medios y alta presión.</p>
                <DropDownFiles  
                  icon='fa-angle-down'
                  text='Ver Fichas Técnicas'
                  style='btn-ficha'
                  styleDropDown='file-link'
                  name='TURBO R'
                  file={`${process.env.PUBLIC_URL}/documents/TURBO R.pdf`}
                  name1='TURBO T'
                  file1={`${process.env.PUBLIC_URL}/documents/TURBO T.pdf`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default Turbos;