import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from '../pages/home';
import Ventiladores from '../components/ventiladores';
import Cortinas from '../components/cortinas';
import Axiales from '../pages/ventiladores/axiales';
import Centrifugos from '../pages/ventiladores/centrifugos';
import Turbos from '../pages/ventiladores/turbos'
import Pesada from '../pages/ventiladores/pesada'
import Filtros from '../pages/ventiladores/filtros'
import Industriales from '../pages/cortinas/industriales';
import Comerciales from '../pages/cortinas/comerciales';

export function Routes(){
  return (
    <Switch>
      <Route path="/" exact component={Home}></Route>
      <Route path="/ventiladores" component={Ventiladores}></Route>
      <Route path="/cortinas-de-aire" component={Cortinas}></Route>
      <Route path="/ventiladores-axiales" component={Axiales}></Route>
      <Route path="/ventiladores-centrífugos" component={Centrifugos}></Route>
      <Route path="/ventiladores-turbo-sopladores" component={Turbos}></Route>
      <Route path="/ventiladores-serie-pesada" component={Pesada}></Route>
      <Route path="/ventiladores-filtros-industriales" component={Filtros}></Route>
      <Route path="/cortinas-de-aire-industriales" component={Industriales}></Route>
      <Route path="/cortinas-de-aire-comerciales" component={Comerciales}></Route>
      <Redirect to="/" />
    </Switch>
  );
}