import React from 'react';
import GeneralButton from '../components/generalButton';
import LinkButton from '../components/linkButton';

interface IDropDown{
  text?: string;
  style: string;
  itemsOne: IDropDownItem[];
  itemsTwo: IDropDownItem[];
  styleDropDown: string;
  styleColumn?: string;
  titleColumnOne: string;
  titleColumnTwo: string;
  icon?: string;
}

export interface IDropDownItem {
  route: string;
  classes: string;
  message: string;
}

class DropDown extends React.Component<IDropDown, {showItems: boolean}>{
  constructor (props: IDropDown){
    super(props);
    this.state = { showItems: false};
  }

  private onClickHandler(showItems: boolean) {
    this.setState({ showItems });
  }

  private getMenu() {
    if (this.state.showItems) {
      return (
        <div className={this.props.styleDropDown}>
          <div className={this.props.styleColumn}>
          <p>{this.props.titleColumnOne}</p>   
          {this.props.itemsOne.map((item, idx) => (
            <LinkButton message={item.message} route={item.route} style={item.classes} key={idx} />
          ))}
          </div>
          <div className={this.props.styleColumn}>
          <p>{this.props.titleColumnTwo}</p>   
          {this.props.itemsTwo.map((item, idx) => (
            <LinkButton message={item.message} route={item.route} style={item.classes} key={idx} />
          ))}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  render(){
    return <div className='drop-down-responsive'>
      <GeneralButton
        message={this.props.text || ''}
        style={this.props.style}
        handleClick={() => this.onClickHandler(!this.state.showItems)}
        icon={this.props.icon}
      />
      {this.getMenu()}
    </div> 
  }
}

export default DropDown;