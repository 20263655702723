import React from 'react';
import { Link } from 'react-router-dom';

interface IImgLinkProps {
  image: string;
  title: string;
  style: string;
  link: string;
}

class ImgLink extends React.Component<IImgLinkProps>{
  render(){
    return <Link to={this.props.link} className={this.props.style}>
        <img src={this.props.image}/>
        <p>{this.props.title}</p>
      </Link>
  }
}

export default ImgLink;