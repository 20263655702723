import React, { useState } from 'react';
import '../styles/header.css';
import logo from '../assets/logo.png';
import { HamburgerSlider } from 'react-animated-burgers';
import DropDown from '../components/dropDown';
import { HashLink as Link } from 'react-router-hash-link';
import { TopBanner } from './topBanner';

export const Header = () => {
  const [isActive, setIsActive] = useState(false);
  const openMenu = () => setIsActive(!isActive)

  const [dropDown, setDropDown] = useState(false)
  const openDropDown = () => setDropDown(!dropDown)
 

  const classes = ( <div className={`menu ${isActive ? 'showMenu' : ''}`}>
    <Link className='link' smooth to='/Home'>INICIO</Link>
      <DropDown 
        icon='fa-angle-down icon-color'
        text="PRODUCTOS"
        style='btn-drop-down'
        titleColumnOne='VENTILADORES'
        titleColumnTwo='CORTINAS DE AIRE'  
        styleColumn='column'
        itemsOne={[
          {
            route: '/ventiladores-axiales',
            message: 'Axiales',
            classes: 'link-style',
          },
          {
            route: '/ventiladores-centrífugos',
            message: 'Centrífugos',
            classes: 'link-style',
          },
          {
            route: '/ventiladores-turbo-sopladores',
            message: 'Turbo Sopladores',
            classes: 'link-style',
          },
          {
            route: '/ventiladores-serie-pesada',
            message: 'Serie Pesada',
            classes: 'link-style',
          },
          {
            route: '/ventiladores-filtros-industriales',
            message: 'Filtros Industriales',
            classes: 'link-style',
          },
        ]}
        itemsTwo={[
          {
            route: '/cortinas-de-aire-comerciales',
            message: 'Cortinas Comerciales',
            classes: 'link-style',
          },
          {
            route: '/cortinas-de-aire-industriales',
            message: 'Cortinas Industriales',
            classes: 'link-style',
          },
        ]}
        styleDropDown='drop-down-style'
      />
    <Link className='link' smooth to='/home/#nosotros'>NOSOTROS</Link>
    <Link className='link' smooth to='/home/#clientes'>CLIENTES</Link>
    <Link className='link' smooth to='/home/#contacto'>CONTACTO</Link>  
       </div>)
  
  
  return <div> 
  <TopBanner />
  <div className='header-container'>
      <div className='brand-container'>
        <Link to='/'><img src={logo} /></Link>
      </div>
      <div className='hamburguer'>
      <HamburgerSlider 
        isActive={isActive} 
        toggleButton={openMenu} 
        buttonColor="#fff" 
        barColor="#1e2d3b" 
      />
      </div>
      {classes}
  </div>
  </div>
}
