import React from 'react';
import {Header} from '../../components/header';
import '../../styles/models.css';
import bcs from '../../assets/modelos/ventilador-bcs.jpg';
import bcf from '../../assets/modelos/bcf.jpg';
import bf from '../../assets/modelos/ventilador-bf.jpeg';
import durafan from '../../assets/modelos/durafan.jpeg';
import DropDownFiles from '../../components/dropDownFiles';

class Centrífugos extends React.Component{
  render(){
    return <div>
      <Header />
      <div className='models'>
        <h4>Centrífugos</h4>
        <div className='models-container'>
          <div className='product-model'>
            <div className='img-box'><img src={bcs} /></div>
            <div className='title-description'>
              <h5>Serie ITS</h5>
              <p>El más moderno diseño para aplicaciones en la industria y el comercio. 
              De construcción robusta, con Rotores AIRFOIL montados sobre el eje mediante buje extractor, 
              que permite un fácil montaje y desmontaje. Autolimitantes de potencia, de elevadísima eficiencia 
              de performance y funcionamiento, asegurando los niveles más bajos de sonido. Rendimientos certificados 
               normas AMCA. <br />
              Modelos simples y doble ancho (SASE o DADE)</p>
              <DropDownFiles  
                icon='fa-angle-down'
                text='Ver Fichas Técnicas'
                style='btn-ficha'
                styleDropDown='file-link'
                name='ITS DADE'
                file={`${process.env.PUBLIC_URL}/documents/BCS DADE.pdf`}
                name1='ITS SASE'
                file1={`${process.env.PUBLIC_URL}/documents/BCS SASE.pdf`}
              />
            </div>
          </div>
          <div className='product-model'>
            <div className='img-box'><img src={bcf} /></div>
            <div className='title-description'>
              <h5>Serie ITF</h5>
              <p>Linea completa de modelos con rotores de palas rectas inclinadas hacia atrás, autolimitantes de 
              potencia y elevadísimo rendimiento en clase I, II y III, para múltiples aplicaciones de aspiración, 
              ventilación, transporte neumático, etc.<br />
              Hay opciones especiales en cuanto a su aplicación con respecto a temperatura o en situaciónes de 
              corrosión. <br />
              Modelos simples o doble ancho (SASE o DADE).</p>
              <DropDownFiles  
                icon='fa-angle-down'
                text='Ver Fichas Técnicas'
                style='btn-ficha'
                styleDropDown='file-link'
                name='ITF DADE'
                file={`${process.env.PUBLIC_URL}/documents/BCF DADE.pdf`}
                name1='ITF SASE'
                file1={`${process.env.PUBLIC_URL}/documents/BCF SASE.pdf`}
              />
            </div>
          </div>
          <div className='product-model'>
            <div className='img-box'><img src={bf} /></div>
            <div className='title-description'>
              <h5>Serie BF</h5>
              <p>Pequeñas unidades centrífugas de transmisión directa para inyección, extracción, enfriamiento, etc. 
              Muy silencioso y de excelente rendimiento, bajo consumo de energía.<br /> 
              La boca de descarga es orientable en cuatro posiciones normales.</p>
              <DropDownFiles  
                icon='fa-angle-down'
                text='Ver Ficha Técnica'
                style='btn-ficha'
                styleDropDown='file-link'
                name='BF'
                file={`${process.env.PUBLIC_URL}/documents/BF.pdf`}
              />
            </div>
          </div>
          <div className='product-model'>
            <div className='img-box'><img src={durafan} /></div>
            <div className='title-description'>
              <h5>Serie Tapones DURA-FAN</h5>
              <p>Económicos de facil adaptación, economizan espacio, eliminan el sistema de conductos complejos y 
              de interceptores en hornos y secaderos. Pueden ser instalados en paredes, pisos y cielorrasos o en plenos. 
              El diseño de montaje abulonado en panel brinda flexibilidad para todo tipo de instalación.</p>
              <DropDownFiles  
                icon='fa-angle-down'
                text='Ver Ficha Técnica'
                style='btn-ficha'
                styleDropDown='file-link'
                name='DURA FAN'
                file={`${process.env.PUBLIC_URL}/documents/DURA FAN.pdf`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default Centrífugos;