import React from 'react';
import {Header} from '../../components/header';
import '../../styles/models.css';
import filtro from '../../assets/modelos/Filtro.jpg';
import filtroF from '../../assets/modelos/filtrof.jpg';

class Filtros extends React.Component{
  render(){
    return <div>
      <Header />
      <div className='models'>
        <h4>Filtros Industriales</h4>
        <div className='models-container'>
          <div className='product-model'>
            <div className='img-box'><img src={filtro} /></div>
            <div className='title-description'>
              <h5>Serie: FP - MC - B</h5>
              <p><strong>FP:</strong>El más pequeño y práctico del grupo, especial para máquinas de baja producción de polvos.<br />
              <strong>MC:</strong>Ciclón de construcción robusta para aspiración de partículas de mayor peso.<br />
              <strong>B:</strong>Los filtros modulares "B" son unidades portátiles construidas con 1, 2 o 3 unidades filtrantes,
              especiales para aspiración de aserrín, virutas, polvos químicos, etc.</p>
            </div>
          </div>
          <div className='product-model'>
            <div className='img-box'><img src={filtroF} /></div>
            <div className='title-description'>
              <h5>Serie: F</h5>
              <p><strong>F:</strong>Filtro de Mangas con limpieza manual o automática desarrollados para separación de polvos industriales, 
              especialmente finos.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default Filtros;